.orders-bar {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: var(--dui-spacing-large);
    gap: var(--dui-spacing-medium);

    .summary {
        display: flex;
        flex-grow: 1;
        flex-wrap: wrap;
        gap: var(--dui-spacing-medium);

        .book-selected {
            display: flex;
            gap: var(--dui-spacing-small);
        }
    }

    .actions {
        display: flex;
        flex-grow: 1;
        flex-shrink: 0;
        justify-self: flex-end;
        justify-content: flex-end;
    }

    &.hidden {
        animation: disappear-to-bottom .2s ease-in forwards;
    }
}

@keyframes disappear-to-bottom {
    from { transform: translateY(0); }
    to { transform: translateY(100%); }
}
