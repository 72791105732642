.footer {
    display: flex;
    flex-direction: column;
    gap: var(--dui-spacing-small);
    background-color: var(--dui-color-background);

    .footer-bottom {
        display: flex;
        justify-content: center;
        gap: var(--dui-spacing-large);
        padding-bottom: var(--dui-spacing-medium);

        .link {
            display: flex;
            align-items: center;
            flex-direction: column;
            flex-shrink: 0;
            text-decoration: none;
            color: var(--dui-color-brand);
            gap: var(--dui-spacing-medium);

            .logo {
                width: auto;
                height: 3rem;
            }

            .details {
                display: flex;
                color: var(--dui-color-accent);
            }
        }
    }

    .footer-top {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: var(--dui-spacing-extra-large);
        justify-content: space-between;
        padding: var(--dui-spacing-small) var(--dui-spacing-extra-large) 0 var(--dui-spacing-extra-large);

        .support-details {
            display: flex;
            flex-basis: 25%;
            flex-direction: column;
            gap: var(--dui-spacing-super-large);

            .app-brand {
                flex-shrink: 0;
                text-decoration: none;
                color: var(--dui-color-brand);

                .link {
                    display: flex;
                    align-items: center;
                    gap: var(--dui-spacing-medium);

                    .logo {
                        width: auto;
                        height: 5rem;
                    }

                    .details {
                        .app-name {
                            text-transform: uppercase;
                            color: var(--dui-color-accent);
                            font-size: var(--dui-font-size-super-large);
                        }
                    }
                }
            }

            .contact-us {
                background-color: unset;

                .contact-content {
                    display: flex;
                    flex-direction: column;
                    gap: var(--dui-spacing-small);

                    .phone-link,
                    .email-link {
                        text-decoration: none;
                        color: var(--dui-color-accent);
                        font-weight: var(--dui-font-weight-medium);
                    }

                    .phone-link {
                        display: flex;
                    }
                }
            }
        }

        @media(max-width: 600px) {
            .support-details {
                display: flex;
                flex-direction: row;
                flex-grow: 1;
            }
        }

        @media(max-width: 500px) {
            .support-details {
                display: flex;
                flex-direction: column;
                flex-grow: 1;
            }
        }

        .explore {
            display: flex;
            flex-grow: 1;
            justify-content: space-evenly;

            .category-list,
            .grade-list,
            .language-list {
                display: flex;
                flex-direction: column;
                gap: var(--dui-spacing-medium);

                .link {
                    color: var(--dui-color-text);
                }
            }
        }

        .actions {
            display: flex;
            flex-direction: column;
            flex-shrink: 0;
            justify-content: flex-end;
            padding: var(--dui-spacing-medium);
            gap: var(--dui-spacing-medium);

        }
        .video-modal-button {
            color: var(--dui-color-accent);
            font-weight: var(--dui-font-weight-light) !important;
        }
    }
}

