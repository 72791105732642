.navbar {
    --navbar-padding-v: var(--dui-spacing-medium);

    display: flex;
    position: relative;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    border-bottom: var(--dui-width-separator-thin) solid var(--dui-color-separator);
    background-color: var(--dui-color-foreground);
    padding: var(--navbar-padding-v) var(--dui-spacing-extra-large);
    color: var(--dui-color-text);
    gap: var(--dui-spacing-extra-large);

    .app-brand {
        flex-shrink: 0;
        order: 1;
        text-decoration: none;
        color: var(--dui-color-brand);

        .link {
            display: flex;
            align-items: center;
            gap: var(--dui-spacing-medium);

            .logo {
                width: auto;
                height: 2rem;
            }

            .app-name {
                display: flex;
                align-items: center;
                text-transform: uppercase;
                line-height: 1;
                color: var(--dui-color-accent);
                font-size: var(--dui-font-size-extra-large);
            }
        }
    }

    .main-menu {
        display: flex;
        align-self: stretch;
        justify-content: center;
        order: 2;
        gap: var(--dui-spacing-large);

        .nav-link {
            margin: calc(-1 * var(--navbar-padding-v)) 0;
            padding: var(--dui-spacing-large);
        }
    }

    .actions {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        order: 3;
        gap: var(--dui-spacing-medium);

        .order-window-message {
            display: flex;
            gap: var(--dui-spacing-small);
        }

        .notification-button {
            position: relative;
            overflow: visible;

            .notification-count {
                display: flex;
                position: absolute;
                top: -0.5em;
                right: -0.5em;
                align-items: center;
                justify-content: center;
                border-radius: 1em;
                background-color: var(--dui-color-danger);
                padding: 2px;
                min-width: 1.4em;
                min-height: 1.4em;
                color: var(--dui-color-text-on-dark);
                font-size: var(--dui-font-size-extra-small);
            }
        }

        .language-selection {
            width: 9rem;

            .icon {
                width: 1rem;
                height: 1rem;
            }
        }
        .user-dropdown {
            padding: var(--dui-spacing-small) var(--dui-spacing-medium);
        }
    }

    @media(max-width: 700px) {
        .app-brand {
            order: 1;
        }

        .actions {
            order: 2;
        }

        .main-menu {
            flex-basis: 100%;
            order: 3;

            .nav-link {
                display: flex;
                flex-grow: 1;
                justify-content: center;
                text-align: center;
            }
        }
    }

}

.user-info {
    display: flex;
    flex-direction: column;
    gap: var(--dui-spacing-small);

    .greetings {
        padding: var(--dui-spacing-small) var(--dui-spacing-medium);
        font-weight: var(--dui-font-weight-bold);
    }
}



.popup {
    max-height: unset!important;
}
