.notifications {
    background-color: var(--dui-color-foreground);
    width: 60vw;
    max-width: 30rem;
    height: 30rem;

    .header {
        padding: var(--dui-spacing-small) var(--dui-spacing-medium);
    }
}
