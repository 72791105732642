.cart-item {
    display: flex;
    border: var(--dui-width-separator-thin) solid var(--dui-color-separator);
    border-radius: var(--dui-radius-border-medium);
    background-color: var(--dui-color-foreground);

    .preview {
        display: flex;
        background-color: var(--dui-color-background);
        width: 8rem;
        height: 12rem;

        .image {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top center;
        }

        .fallback-icon {
            opacity: 0.3;
            border: var(--dui-width-separator-thin) solid var(--dui-color-separator);
            padding: var(--dui-spacing-large);
            width: 100%;
            height: 100%;
        }
    }

    .details {
        flex-grow: 1;
        background-color: unset;

        .book-meta {
            display: flex;
            flex-direction: column;
            gap: var(--dui-spacing-small);
        }

        .actions {
            align-self: flex-end;
        }

        .quantity-input {
            width: 6rem;
        }
    }


    @media(max-width: 400px) {
        flex-direction: column;

        .preview {
            width: 100%;
        }
    }
}
