.order-success-modal {
    max-width: 28rem;
    height: 100%!important;
    max-height: 32rem;

    .content {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: var(--dui-spacing-extra-large);
        padding: var(--dui-spacing-extra-large);

        .logo {
            width: 6rem;
            height: 6rem;
            object-fit: contain;
        }

        .success-message {
            align-items: center;
            text-align: center;
            color: var(--dui-color-brand);
            font-size: var(--dui-font-size-large);
            font-weight: var(--dui-font-weight-bold);
        }

        .order-details {
            display: flex;
            flex-direction: column;
            gap: var(--dui-spacing-small);
        }

        .help-text {
            text-align: center;
        }
    }

    .footer {
        justify-content: center;
    }
}

.modal-backdrop {
    align-items: stretch;
    justify-content: flex-end;

    .orders-modal {
        border-radius: unset;
        height: 100%;
        max-height: unset;
        animation: appear-from-right .3s ease-in forwards;

        .footer {
            flex-wrap: wrap;
        }

        .footer-actions {
            display: flex;
            flex-grow: 1;
            flex-shrink: 0;
            justify-content: flex-end;
        }

        .cart-item-list {
            display: flex;
            flex-direction: column;
            gap: var(--dui-spacing-small);
        }
    }

    @media(max-width: 700px) {
        .orders-modal {
            border-top-left-radius: var(--kitab-radius-border);
            border-top-right-radius: var(--kitab-radius-border);
            width: 100%;
            max-width: 100%;
            height: 96%;
            animation: appear-from-bottom .3s ease-in forwards;
        }
    }
}

@keyframes appear-from-right {
    from { transform: translateX(100%); }
    to { transform: translateX(0); }
}

@keyframes appear-from-bottom {
    from { transform: translateY(100%); }
    to { transform: translateY(4%); }
}
