:root {
    --dui-font-weight-light: 400;
    --dui-font-weight-medium: 400;
    --dui-font-weight-bold: 700;
    --dui-color-primary: #122344;
    --dui-color-brand: #122344;
    --dui-color-accent: #00adb5;
    --dui-color-text: #414141;
    --dui-font-family-sans-serif: 'Inter', 'Mukta', sans-serif;
    --dui-color-background: #d9e8e8;
    --dui-color-background-hover: #d9e8e8;
    --dui-color-background-information: #ffffff;
    --dui-color-background-hover-primary-button: #0098a6;

    --dui-color-cornflower-blue: #2A4494;
    --dui-color-elton-blue: #82ca9d;
    --dui-color-maximum-yellow-red: #ffc658;
    --dui-color-medium-purple: #8884d8;
    --dui-color-princeton-orange: #FC893C;
    --dui-color-rose-madder: #D83A3A;
    --dui-color-deep-taupe: #7A6563;
    --dui-color-brandy: #803434;

    --dui-font-size-extra-small: 0.7rem;
    --dui-font-size-small: 0.8rem;
    --dui-font-size-medium: 1rem;
    --dui-font-size-large: 1.2rem;
    --dui-font-size-extra-large: 1.5rem;
    --dui-font-size-super-large: 2rem;

    --kitab-page-max-width: 76rem;
    --kitab-page-background-color: #f9f9fa;
    --kitab-warning-color: #fff8eb;
    --kitab-radius-border: 0.5rem;
}

html {
    background-color: var(--kitab-page-background-color);

    @media(max-width:900px) {
        font-size: 14px;
    }

    @media(max-width: 700px) {
        font-size: 13px;
    }

    @media(max-width: 400px) {
        font-size: 12px;
    }
}

.base {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: auto;
    scrollbar-gutter: stable;

    .preload {
        flex-grow: 1;
        min-height: 70vh;
    }

    .navbar {
        flex-shrink: 0;

        &.hidden {
            display: none;
        }
    }

    .view {
        flex-grow: 1;
    }

    .footer {
        flex-shrink: 0;
    }

    .alert-container {
        position: fixed;
        right: var(--dui-spacing-large);
        bottom: var(--dui-spacing-large);
        z-index: 1;
        width: 20rem;
    }

    .orders-bar {
        position: fixed;
        bottom: 0;
        left: 0;
        box-shadow: 0 -3px 5px -2px rgba(0, 0, 0, .3);
        background-color: var(--dui-color-foreground);
        width: 100%;
        animation: appear-from-bottom .2s ease-in forwards;
    }

    .goto-top-button {
        position: fixed;
        right: var(--dui-spacing-super-large);
        bottom: calc(2 * var(--dui-spacing-super-large));
        transition: ease-in-out;
        animation: scaleAnimation 0.5s;
        font-size: var(--dui-font-size-extra-large);
    }
}

@keyframes scaleAnimation {
    from {
        transform: scale(0);
        opacity: 0;
    }

    to {
        transform: scale(1);
        opacity: 100%;
    }
}

@keyframes appear-from-bottom {
    from {
        transform: translateY(100%);
    }

    to {
        transform: translateY(0);
    }
}