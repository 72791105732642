.nagbar {
    --nagbar-padding-v: var(--dui-spacing-medium);

    display: flex;
    position: relative;
    align-items: center;
    flex-wrap: wrap;
    border-bottom: var(--dui-width-separator-thin) solid var(--dui-color-separator);
    background-color: var(--kitab-warning-color);
    padding: var(--nagbar-padding-v) var(--dui-spacing-extra-large);
    color: var(--dui-color-text);
    gap: var(--dui-spacing-large);

    .nagbar-item {
        display: flex;
        align-items: center;
        gap: var(--dui-spacing-small);

        .dot {
            flex-shrink: 0;
            font-size: var(--dui-font-size-extra-small);
        }
    }
}
